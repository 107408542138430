<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить зону" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Ваша зона</label>
                        <b-input v-model="zone.name" placeholder="Зона" />
                    </b-col>
                    <b-col cols="6">
                        <label>Ваш населенный пункт</label>
                        <v-select
                            v-model="village_select"
                            @input="setSelected"
                            placeholder="Населенный пункт"
                            label="name"
                            :options="villages"
                            class="select-size-md"
                        />
                    </b-col>
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button
                        :disabled="submitButtonDisabled"
                        class="mt-2 col-md-5"
                        variant="primary"
                        @click="createZone">
                            Добавить
                        </b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                            Отмена
                        </b-button>
                    </b-col>
                    <!-- <b-col >
                        <b-button @click="createZone()" class="mt-2" variant="primary">Добавить</b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel()">Отмена</b-button>
                    </b-col> -->
                </b-row>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'   

export default {
    components: {
        vSelect,
        ToastificationContent,
    },
    props: ['polygon'],
    data(){
        return {
            zone: {
                name: '',
                village_id: '',
                polygon: ''
            },
            village_select: undefined,
            villages: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        createZone(){
            this.submitButtonDisabled = true
            this.$http
                .post('addresses/zones', this.zone)
                .then(res => {
                    this.$bvModal.hide('zoneModal')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Зона успешно добавлена!`,
                        },
                    })
                    this.closeModal()
                    // this.$bvModal.hide(this.$route.name + 'CreateModal')
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get('addresses/villages').then(res => {
                    this.villages = res.data
                })
            this.clearData()
        },
        setSelected(){
            this.zone.village_id = this.village_select.id
        },
        closeModal(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.zone = {
                name: '',
                village_id: '',
                polygon: ''
            },
            this.village_select = undefined
        }
    }
}
</script>